<template>
  <div>
    <slot></slot>
    <div class="grid grid-cols-2 md:grid-cols-3 gap-5 mt-5 md:mt-6">
      <div
        class="mr-5 md:mb-2 rounded h-56px flex flex-col p-3 cursor-pointer transform duration-300 hover:-translate-y-1"
        :class="{ 'bg-deal': !item.image }"
        :style="{
          'background-image': 'url(' + item.image + ')',
          'background-size': 'contain',
          'background-position': 'center',
          'background-repeat': 'no-repeat'
        }"
        v-for="(item, i) in items"
        :key="i"
        @click="selectData(item)"
      >
        <h3
          class="text-center text-xs text-white mt-3"
          v-text="item.business_name"
          v-if="!item.image"
        ></h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    selectData(data) {
      this.$emit("selected", data);
    }
  }
};
</script>
